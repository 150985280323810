import { NextFont } from 'next/dist/compiled/@next/font';
import { apercuBlack, apercuLight, apercuBold, paytonOne, poppins } from '@/styles/fonts';

type FontMapping = {
  font: NextFont | undefined;
  publicationFont: NextFont | undefined;
  additionalFont?: NextFont | undefined;
};

const fontMappings: { [key: string]: FontMapping } = {
  curiously: {
    font: poppins,
    publicationFont: paytonOne,
  },
  mirror: {
    font: apercuLight,
    publicationFont: apercuBlack,
    additionalFont: apercuBold,
  },
  dailystar: {
    font: poppins,
    publicationFont: apercuBlack,
  },
};

export default fontMappings;
