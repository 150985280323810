/**
 * Maps class names to their corresponding styles from a styles object.
 *
 * @param {Object.<string, string>} styles - An object mapping class names to their styles.
 * @param {...(string | undefined)} classes - Class names to be mapped.
 * @returns A string of mapped class names joined by a space.
 */
const mapClassNames = (styles: { [key: string]: string }, ...classes: (string | undefined)[]): string =>
  classes
    .filter((cls): cls is string => typeof cls === 'string' && cls !== 'undefined')
    .map((cls) => styles[cls])
    .filter(Boolean)
    .join(' ');

/**
 * Joins class names into a single string, filtering out falsy values.
 *
 * @param {...(string | undefined | null | false)} classes - Class names to be joined.
 * @returns A string of class names joined by a space.
 */
const joinClassNames = (...classes: (string | undefined | null | false)[]): string =>
  classes.filter((cls): cls is string => Boolean(cls)).join(' ');

export { mapClassNames, joinClassNames };
